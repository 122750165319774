import React, { useState } from 'react';

// react redux
import { connect } from 'react-redux';

// @material-ui react
import {
    Box,
    Breadcrumbs,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Link,
    makeStyles,
    Paper,
    TextField,
    Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CameraIcon from '@material-ui/icons/CropFree';

// assets
import { ThreeDots } from 'svg-loaders-react';
import ScanImage from '../../assets/images/scan/scan.gif'

// services
import * as ApiService from '../../services/ApiService';

// shared
import QRCode from '../../shared/components/QRCode/QRCode';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        }
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(5),
        margin: 'auto',
        maxWidth: "500px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(5)
    },
    inputQr: {
        border: "none",
        "&:focus": {
            border: "none",
            outline: "none"
        },
        zIndex: -1,
        position: "relative"
    },
    alertRegistro: {
        marginTop: theme.spacing(3)
    },
    button: {
        textTransform: 'none'
    },
}));

const PageBreadcrumb = () => {
    return <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/">Home</Link>
        <Typography color="textPrimary">Info</Typography>
    </Breadcrumbs>
}

const Info = (props) => {
    const [token, setToken] = useState("");
    const [loading, setLoading] = useState(false);
    const [person, setPerson] = useState(null);
    const [personError, setPersonError] = useState(false);
    const [personErrorTimer, setPersonErrorTimer] = useState();
    const textInput = React.useRef(null);
    const { scanMode } = props;
    const classes = useStyles();

    React.useEffect(() => {
        if (textInput.current) {
            textInput.current.focus();
        }

        window.addEventListener('keydown', upHandler);
        // cleanup this component
        return () => {
            window.removeEventListener('keydown', upHandler);
        };
    }, []);

    React.useEffect(() => {
        if (scanMode === "camera") {
            if (token !== "") {
                handleQr();
                setToken("");
            }
        }
    }, [token]);

    const upHandler = ({ key }) => {
        if (textInput.current) {
            textInput.current.focus();
        }
    };

    const handleQr = async () => {
        setLoading(true);
        console.log(token);
        try {
            if (personErrorTimer) {
                setPersonError(false);
                clearTimeout(personErrorTimer);
            }

            const data = await ApiService.getPersonToken(token);
            console.log(data);
            setPerson(data.person);
        } catch (error) {
            console.log(error);
            setPersonError(true);
            setPersonErrorTimer(() => {
                setTimeout(() => {
                    setPersonError(false);
                }, 6000);
            });
        }

        setLoading(false);
    };

    const handleBackScan = () => {
        setPerson(null);
    };

    return <React.Fragment>
        <PageBreadcrumb />
        <Box className="mt-4">
            <Typography variant="h3">Scan Person Info</Typography>
        </Box>
        <Paper className={classes.paper}>
            {loading === true ?
                <ThreeDots stroke="#e63832" /> :
                <Box display="block" alignItems="center" justifyContent="center">
                    {personError === true ? <Alert severity="error">Error al Leer. Favor de Intentar nuevamente</Alert> : ""}
                    {person ?
                        <Box display="block" alignItems="center" justifyContent="center">
                            <Typography variant="h3" align="center">{person.name}</Typography>
                            <Grid container spacing={2} className="mt-2">
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Email"
                                        id="email"
                                        disabled
                                        fullWidth
                                        margin="dense"
                                        value={person.email}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Unidad Operativa"
                                        id="unidadOperativa"
                                        disabled
                                        fullWidth
                                        margin="dense"
                                        value={person.unidad_operativa}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Kit Location"
                                        id="kitLocation"
                                        disabled
                                        fullWidth
                                        margin="dense"
                                        value={person.kit_location}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Unique ID"
                                        id="id"
                                        disabled
                                        fullWidth
                                        margin="dense"
                                        value={person.id}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Person Token"
                                        id="token"
                                        disabled
                                        fullWidth
                                        margin="dense"
                                        value={btoa(person.token)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <FormControlLabel disabled control={
                                        <Checkbox checked={person.is_checked_in} name="checkedIn" />
                                    } label="Checked in" />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        fullWidth
                                        className={classes.button}
                                        startIcon={<CameraIcon />}
                                        onClick={handleBackScan}
                                    >
                                        Back to scan
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box> :
                        <div>
                            {scanMode === "camera" ?
                                <QRCode onSuccessCallback={(code) => {
                                    setToken(code) // continue in useEffect...
                                }} /> :
                                <div>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center">
                                        <img alt="logo" src={ScanImage} style={{ maxWidth: 350 }} />
                                    </Box>
                                    <input ref={textInput}
                                        className={classes.inputQr}
                                        value={token}
                                        onChange={e => {
                                            console.log(e.nativeEvent.data)
                                            setToken(token + e.nativeEvent.data);
                                        }}
                                        onKeyUp={e => {
                                            if (e.key === 'Enter') {
                                                console.log("token:" + token);
                                                handleQr();
                                                setToken("");
                                            }
                                        }}
                                    />
                                </div>
                            }
                        </div>
                    }
                </Box>
            }
        </Paper>
    </React.Fragment>
}

const mapStateToProps = ({ drawerReducer }) => ({
    scanMode: drawerReducer.scanMode
});

export default connect(mapStateToProps, {})(Info);