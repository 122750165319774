import React from 'react';

// @material-ui/core components
import Box from "@material-ui/core/Box";

// core components
import Footer from './../Footer/Footer'

// styles
import styled from 'styled-components';

// shared
import Navbar from '../Navbar/Navbar';

const Root = styled.div`
    ${({ theme }) => `
        top: 0;
        height: 100vh;
        position: relative;

        .main-panel{
            width: 100%;
            overflow: auto;
            position: relative;
            max-height: 100%;
            transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
            overflow-scrolling: touch;

            .content{
                margin-top: 70px;
                padding: 30px 15px;
                min-height: calc(100vh - 123px);
    
                .container{
                    ${theme.breakpoints.up("md")} {
                        padding-right: 90px;
                        padding-left: 90px;
                    }
                    padding-right: 15px;
                    padding-left: 15px;
                    margin-right: auto;
                    margin-left: auto;
                }
            }
        }

    `}
`

const Main = (props) => {
    const { children } = props;

    /*
    const [mobileOpen, setMobileOpen] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener("resize", resizeFunction);
        return function cleanup() {
            window.removeEventListener("resize", resizeFunction);
        };
    }, []);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
          setMobileOpen(false);
        }
    }; 
    */
   
    return (
        <Root>
            <Navbar />
            <Box className="main-panel">    
                <main className="content">
                    <div className="container">
                        {children}
                    </div>
                </main>
                <Footer></Footer>
            </Box>
        </Root>
    );
};

export default Main;