import { BrowserRouter as Router } from "react-router-dom";

// Components
import Routes from './routes/Routes';

// Styles
import Theme from './theme';
import './App.css';
import './assets/styles/bootstrap-utilities.css';
import { StylesProvider } from "@material-ui/core/styles";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

function App() {
  return (
    <StylesProvider injectFirst>
      <Theme>
        <Router history={history}>
          <Routes />
        </Router>
      </Theme>
    </StylesProvider>
  );
}

export default App;
