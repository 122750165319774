import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  
    Breadcrumbs,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Grid,
    Link,
    makeStyles,
    Typography,
    colors,
   
} from '@material-ui/core';

// services
import * as ApiService from '../../services/ApiService';

// shared 
import Loader from '../../shared/components/Loader/LoaderContent';

const delay = 30;

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(2)
    },
    avatar: {
        backgroundColor: colors.red[500],
    },
}))

const PageBreadcrumb = () => {
    return <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/">Home</Link>
        <Typography color="textPrimary">Dashboard</Typography>
    </Breadcrumbs>
}

const Dashboard = (props) => {
    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const [openLoader, setOpenLoader] = React.useState(false);

    React.useEffect(() => {
        getData();

        const timer1 = setInterval(() => getData(), delay * 1000);
        return () => {
            clearInterval(timer1);
        };
    }, []);

    const getData = async () => {
        try {
            setOpenLoader(true);
            const data = await ApiService.getDashboard();
            setData(data);
            setOpenLoader(false);
            console.log(JSON.stringify(data));
        } catch (e) {
            console.log(e.message);
        }
    };

    return <React.Fragment>
        <PageBreadcrumb />
        <div className={classes.root}>
            <Loader loading={openLoader} transparent={true}>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start">
                    {data.map(item => {
                        return <Grid item xs={12} sm={6} md={6} key={item.id}>
                            <Card variant="outlined">
                                <CardActionArea component={RouterLink} to={`/location/${item.id}/log`} target="_blank" >
                                    <CardContent>
                                        <Typography variant="h3">{`${item.name}, ${item.city_name}`}</Typography>
                                        <Typography variant="h1" align="center" className="m-3">{item.person_count}</Typography>
                                        <Typography variant="h5" align="center">{item.person_count > 1 ? "active people" : "active person"}</Typography>
                                    </CardContent>
                                    <CardActions></CardActions>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    })}
                </Grid>
            </Loader>
        </div>
    </React.Fragment>
}

export default Dashboard;