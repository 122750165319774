import React from 'react';
import { Box } from '@material-ui/core';
import QrcodeScannerV2 from './QrcodeScannerV2/QrcodeScannerV2';
import { useMediaQuery } from '@material-ui/core';

const QRCode = ({ onSuccessCallback }) => {
    let qrBox = 200;

    if (useMediaQuery(theme => theme.breakpoints.up('sm'))) {
        qrBox = 350;
    }

    if (useMediaQuery(theme => theme.breakpoints.up('md'))) {
        qrBox = 350;
    }

    return <Box className="mt-2 mb-2">
         <QrcodeScannerV2
            fps={10}
            qrBox={qrBox}
            disableFlip={false}
            qrCodeSuccessCallback={onSuccessCallback} />
        {/* <Html5QrcodeScannerPlugin
            fps={10}
            qrBox={qrBox}
            disableFlip={false}
            qrCodeSuccessCallback={onSuccessCallback} /> */}
    </Box>
}

export default QRCode;