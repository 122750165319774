import React, { useState } from 'react';

// redux
import { useDispatch } from "react-redux";
import * as locationActions from '../../redux/actions/locationActions';

// @material-ui/core components
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';
import Sidebar from '../Sidebar/Sidebar';
import LocationMenu from './LocationMenu/LocationMenu';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

// servicess
import * as ApiService from '../../services/ApiService';

const Navbar = (props) => {
    const dispatch = useDispatch();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [locations, setLocations] = useState([]);
    const [location, setLocation] = useState({ id: "" });

    const handleDrawerToggle = (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenDrawer(open => !open);
    };

    const handleChangeLocation = (event) => {
        const item = locations.filter(l => l.id === event.target.value)[0];
        setLocation(item);
        dispatch(locationActions.applyLocation(item));
    };

    React.useEffect(() => {
        const getLocations = async () => {
            try {
                const data = await ApiService.getLocations();
                setLocations(data);
                setLocation(data[0]);
                dispatch(locationActions.applyLocation(data[0]));
                
                console.log(`location data: ${data}`);
            } catch (e) {
                console.log(e.message);
            }
        };

        getLocations();
    }, []);
    
    return <AppBar position="fixed">
        <Toolbar>
            <Box flexGrow={1}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}>
                    <MenuIcon />
                </IconButton>
            </Box>
            <Box className="mr-2">
                <FormControl>
                    <InputLabel id="location-label">Location</InputLabel>
                    <Select
                        labelId="location-label"
                        id="location"
                        value={location.id || ""}
                        onChange={handleChangeLocation}>
                        {locations.map(l => <MenuItem key={l.id} value={l.id}>{`${l.city_name} - ${l.name}`}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>
            <LocationMenu />
        </Toolbar>
        <Sidebar open={openDrawer} onClose={handleDrawerToggle} />
    </AppBar>
}

export default Navbar;