const API_ENDPOINT = 'https://e7zgio9xsa.execute-api.us-west-2.amazonaws.com/v1';

const SCAN_PATH = `${API_ENDPOINT}/scan`;
const LOCATION_PATH = `${API_ENDPOINT}/locations`;
const DASHBOARD_PATH = `${API_ENDPOINT}/dashboard`;
const PERSONS_PATH = `${API_ENDPOINT}/persons`;

export const scan = async (body) =>{
    const url = `${SCAN_PATH}`;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(body)
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getDashboard = async () => {
    const url = `${DASHBOARD_PATH}`;

    const requestOptions = {
        method: 'GET'
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getLocations = async () =>{
    const url = `${LOCATION_PATH}`;
    const requestOptions = {
        method: 'GET'
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getLocation= async (id) => {
    const url = `${LOCATION_PATH}/${id}`;

    const requestOptions = {
        method: 'GET'
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getLocationsCurrent = async (id) => {
    const url = `${LOCATION_PATH}/${id}/current`;

    const requestOptions = {
        method: 'GET'
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getLocationsLog = async (id) => {
    const url = `${LOCATION_PATH}/${id}/log`;

    const requestOptions = {
        method: 'GET'
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const clearLocations = async (id) => {
    const url = `${LOCATION_PATH}/${id}/clear`;

    const requestOptions = {
        method: 'GET'
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

export const getPersonToken = async (token) => {
    const url = `${PERSONS_PATH}/token/${token}`;

    const requestOptions = {
        method: 'GET'
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    if (!response.ok) {
        // get error message from body or default to response statusText
        const error = data || response.statusText;
        return Promise.reject(error);
    }

    return data;
}