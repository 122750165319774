import React from 'react';

import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

// styles
import { ThemeProvider } from "styled-components";
import { MuiThemeProvider } from "@material-ui/core/styles";



const theme = createMuiTheme({
    palette,
    typography,
    overrides,
    zIndex: {
      appBar: 1200,
      drawer: 1100
    }
});

const Theme = ({children}) => {
    

    return <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
        {children}
        </ThemeProvider>
    </MuiThemeProvider>
}

export default Theme;
