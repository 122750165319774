import * as locationTypes from '../types/locationTypes'

const INITIAL_STATE = {
  location: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case locationTypes.APPLY_LOCATION:
      return { ...state, location: action.payload };
    default:
      return state;
  }
};