import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
/* const grey = '#989898'*/
const lightGrey = '#e8e8e8'
const transparent = 'transparent';
const blue = '#00C1DE';
const pink = '#EC1C6D';
const orange = '#FF9300';
const yellow = '#EDBF00';
const purple = '#B474D9';
const deepBlue = '#0030FF';
const lightPink = '#FC7EB2';
const green = '#84E349';
const red = '#e63832';
const indigo = '#041860';


export default {
  black,
  white,
  transparent,
  dark: {
    contrastText: white,
    dark: '#222222',
    main: '#383737',
    light: '#4e4e4e'
  },
  primary: {
    contrastText: white,
    dark: black,
    main: red,
    light: colors.red[500]
  },
  secondary: {
    contrastText: black,
    dark: black,
    main: white,
    light: lightGrey
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: green,
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: blue,
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: orange,
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: red,
    light: colors.red[400]
  },
  text: {
    primary: '#333333',
    secondary: '#808080',
    tertiary: '#999999',
    link: colors.blue[600],
    disabled: colors.grey[400],
    placeholder: 'red'
  },
  icon: {
    primary: blue,
    secondary: '#808080',
    link: colors.blue[600],
    disabled: colors.grey[300]
  },
  grey: {
    contrastText: white,
    dark: '#333333',
    main: '#808080',
    light: '#999999',
    translucent: colors.grey[200],
    lowest: colors.grey[100]
  },
  background: {
    default: '#F4F6F8',
    paper: white,
    primary: colors.blue[700]
  },
  border:{
    input: '#c1c5c9',
    inputHover: blue
  },
  divider: colors.grey[200],
  colors: {
    blue,
    pink,
    orange,
    yellow,
    purple,
    deepBlue,
    lightPink,
    green,
    red,
    indigo,
  },
};
