import React from 'react';

// @material-ui/core components
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/AccountCircle';

const Info = (props) => {
    return <ListItem button onClick={props.onClick}>
        <ListItemIcon><PersonIcon /></ListItemIcon>
        <ListItemText primary="Person Info" />
    </ListItem>
};

export default Info;