import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MenuItem } from '@material-ui/core';

const ViewLog = (props) => {
    const history = useHistory();

    const handleClick = () => {
        history.push(`/location/${props.location.id}/log`);
        props.handleOpenMenu(false);
    }

    return <MenuItem onClick={handleClick}>View Log</MenuItem>
}

const mapStateToProps = ({ locationReducer }) => ({
    location: locationReducer.location
});

export default connect(mapStateToProps, {})(ViewLog);