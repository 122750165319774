import React from 'react';

// @material-ui/core components
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';

const Dashboard = (props) => {
    return <ListItem button onClick={props.onClick}>
        <ListItemIcon><DashboardIcon /></ListItemIcon>
        <ListItemText primary="Dashboard" />
    </ListItem>
};

export default Dashboard;