import React from 'react';
import { connect } from 'react-redux';
import { MenuItem } from '@material-ui/core';

import * as ApiService from '../../../../services/ApiService';

const ClearLocation = (props) => {
    const handleClick = async () => {
        if (window.confirm("Are you sure you want to clear the current location?")) {
            try {
                await ApiService.clearLocations(props.location.id);
                alert("The location was successfully emptied.")
            } catch (e) {
                console.log(e.message);
            }
        }
        props.handleOpenMenu(false);
    };
    return <MenuItem onClick={handleClick}>Clear Location</MenuItem>
}

const mapStateToProps = ({ locationReducer }) => ({
    location: locationReducer.location
});

export default connect(mapStateToProps, {})(ClearLocation);