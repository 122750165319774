import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/Camera';

const Camera = (props) => {
    return <ListItem button onClick={props.onClick}>
        <ListItemIcon><CameraIcon /></ListItemIcon>
        <ListItemText primary="Use Camera" />
    </ListItem>
}

export default Camera;