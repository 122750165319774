import * as drawerTypes from '../types/drawerTypes'

const INITIAL_STATE = {
  scanMode: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case drawerTypes.APPLY_SCAN_MODE:
      return { ...state, scanMode: action.payload };
    default:
      return state;
  }
};