import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Redirect } from 'react-router';

// layouts
import { Main as MainLayout} from '../layout';

import Home from '../pages/Home/Home';

import pages from './routelinks'
import RouteWithLayout from './RouteWithLayout'


const PrivateRoute = (props) => {
   
    const { layout: Layout, component: Component, title, subtitle, ...rest } = props;
    
    return(
        <Route {...rest} render={matchProps => (
            <Layout title={title} subtitle={subtitle}>
                    <Component {...matchProps} />
                </Layout>
        )} />
    )
}

const Routes = () => {
    const privateRoutes = [];

    for(const page of pages){
        if(page.path){
            privateRoutes.push(
    
                <PrivateRoute 
                    key={page.id} 
                    component={page.component} 
                    exact layout={MainLayout} 
                    path={page.path}
                />
            )
        }

        if(page.children){
            page.children.map(child => {
                privateRoutes.push(

                    <PrivateRoute 
                        key={child.id} 
                        component={child.component} 
                        exact layout={MainLayout} 
                        path={child.path}
                    />
                )
            })
        }
    }

    return (
        <Switch>
            <RouteWithLayout component={Home} exact layout={MainLayout} path="/"/>
            {privateRoutes}
            <Redirect to={"/"} />
        </Switch>
    );
};

export default Routes;