import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';

const Scanner = (props) => {
    return <ListItem button onClick={props.onClick}>
        <ListItemIcon><CenterFocusStrongIcon /></ListItemIcon>
        <ListItemText primary="Use Scanner" />
    </ListItem>
}

export default Scanner;