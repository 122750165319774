import React from 'react';

// material components
import MuiBackdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// styles
import styled from 'styled-components';

const Backdrop = styled(MuiBackdrop)`
    ${({ theme,  transparent}) => `
        background-color: ${transparent ? 'rgb(255 255 255 / 66%)' :'rgb(240 244 250 / 66%)'};
        z-index: ${theme.zIndex.drawer + 1};
        position: absolute;
        border-radius: 20px;
    `}
`

const Loader = ({open = false, transparent = false}) => {
    return (
        <Backdrop open={open} transparent={Number(transparent)}>
            <CircularProgress color="primary" />
        </Backdrop>
    );
};

export default Loader;