import React from 'react';
import { useHistory } from 'react-router-dom';

// redux
import { useDispatch } from "react-redux";
import * as drawerActions from '../../redux/actions/drawerActions';

// @material-ui/core components
import Box from "@material-ui/core/Box";

// styles
import DrawerStyled from '../../assets/styles/layout/sidebarStyle';
import Brand from './Brand/Brand';
import Dashboard from './Dashboard/Dashboard';
import Camera from './Camera/Camera';
import Scanner from './Scanner/Scanner';
import Info from './Info/Info';

const Sidebar = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleCamera = () => {
        dispatch(drawerActions.applyScanMode("camera"));
        props.onClose();
    };

    const handleScanner = () => {
        dispatch(drawerActions.applyScanMode("scanner"));
        props.onClose();
    };

    const handleDashboard = () => {
        history.push("/dashboard");
        props.onClose();
    };

    const handleBrand = () => {
        props.onClose();
        //history.push("/");
    };

    const handleInfo = () => {
        history.push("/info");
        props.onClose();
    };

    return (
        <Box>
            <DrawerStyled
                anchor="left"
                variant="temporary"
                open={props.open}
                onClose={props.onClose}>
                <Brand onClick={handleBrand} />
                <Dashboard onClick={handleDashboard}/>
                <Camera onClick={handleCamera} />
                <Scanner onClick={handleScanner} />
                <Info onClick={handleInfo} />
            </DrawerStyled>
        </Box>
    );
};

export default Sidebar;