// @material-ui/core components
import MuiDrawer from "@material-ui/core/Drawer";
// styles
import styled from 'styled-components';

export default styled(MuiDrawer)`
    ${({ theme }) => `
        .MuiDrawer-paper{
            background-color: #FFFFFF;
            top: 0;
            left: 0;
            width: 260px;
            border: none;
            height: 100%;
            bottom: 0;
            z-index: 1;
            position: fixed;
            box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
            ${theme.breakpoints.up("md")} {
                width: 260px;
                position: fixed;
                height: 100%;
            }
            ${theme.breakpoints.down("sm")} {
                top: 0;
                left: 0;
                right: 0;
                width: 260px;
                height: 100vh;
                display: block;
                z-index: 1032;
                position: fixed;
                border-top: none;
                box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
                overflow-y: visible;
                text-align: left;            
                visibility: visible;
                padding-left: 0;
                padding-right: 0px;
            }
        }

        .link-item{
            width: auto;
            margin: 0 20px;
            display: block;
            padding: 8px 15px;
            position: relative;
            background-color: transparent;

            .logout-button{
                margin-top: 8px;
                padding: 8px 0;
                font-weight: 300;
                line-height: 1.5em;
                font-size: 14px;
                color: ${theme.palette.white};
                display: flex;
                align-items: center;
            }
            .icon{
                margin-right: 15px;
                color: ${theme.palette.icon.secondary};
            }
            .text{
                color: ${theme.palette.white};
                font-size: 14px;
                font-weight: 300;
                line-height: 30px;
            }
        }
    `}
`