import React from 'react';

// @material-ui/core components
import Box from "@material-ui/core/Box";

// assets
import LogoTalisis from "../../assets/images/logos/talisis.png";

const Footer = () => {
    return (
        <Box display="flex" alignItems="center" justifyContent="center">
                
                <img alt="logo" src={LogoTalisis} style={{width: 60}}/>
        </Box>
    );
};

export default Footer;