import React from 'react';
import {
    ClickAwayListener,
    Grow,
    IconButton,
    makeStyles,
    MenuList,
    Paper,
    Popper
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import ClearLocation from './ClearLocation/ClearLocation';
import ViewLog from './ViewLog/ViewLog';

const useStyles = makeStyles((theme) => ({
    menu: {
        display: "flex",
    },
    icon: {
        minWidth: 32,
    }
}));

const LocationMenu = () => {
    const classes = useStyles();
    const [openMenu, setOpenMenu] = React.useState(false);
    const anchorRef = React.useRef(null);
    const prevOpen = React.useRef(openMenu);
    
    React.useEffect(() => {
        if (prevOpen.current === true && openMenu === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = openMenu;
    }, [openMenu]);

    const handleToggle = (e) => {
        e.stopPropagation();
        setOpenMenu((prevOpen) => !prevOpen);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        if (anchorRef.current && anchorRef.current.contains(e.target)) {
            return;
        }
        setOpenMenu(false);
    };

    return <div className={classes.menu}>
        <IconButton
            ref={anchorRef}
            edge="end"
            color="inherit"
            aria-label="settings"
            aria-controls={openMenu ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle} >
            <MoreVertIcon />
        </IconButton>
        <Popper open={Boolean(openMenu)} anchorEl={anchorRef.current} transition disablePortal onClick={(e) => e.stopPropagation()}>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="menu-list-grow" role="">
                                <ViewLog handleOpenMenu={setOpenMenu} />
                                <ClearLocation handleOpenMenu={setOpenMenu} />
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </div>
}

export default LocationMenu;