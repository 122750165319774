import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// @material-ui/core components
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

// assets
import LogoTalisis from "../../../assets/images/logos/logo.png";

// styles
import styled from 'styled-components';

const Root = styled.div`
    position: relative;
    padding: 15px 15px;
    z-index: 4;
    &:after: {
        content: '""',
        position: absolute;
        bottom: 0;
        height: 1px;
        right: 15px;
        width: calc(100% - 30px);
        background-color: rgba(180, 180, 180, 0.3);
    }
`

const Brand = (props) => {
    return (
        <Root>
            <Link color="inherit" component={RouterLink} to="/" onClick={props.onClick}>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <img alt="logo" src={LogoTalisis} style={{ maxWidth: "200px" }} />
                </Box>
            </Link>
        </Root>
    );
};

export default Brand;