import React from 'react';
import PropTypes from 'prop-types'

// @material-ui/core components
import Box from '@material-ui/core/Box';

// core components
import Loader from './Loader'

const LoaderContent = ({children, loading, transparent}) => {
    return (
        <Box width="100%" position="relative">
            {children}
            <Loader open={loading} transparent={transparent} />
        </Box>
    );
};

LoaderContent.defaultProps = {
    loading: false,
};

LoaderContent.propTypes = {
    children: PropTypes.element,
    loading: PropTypes.bool
};

export default LoaderContent;