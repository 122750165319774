import React , { useState } from 'react';

import * as ApiService from '../../services/ApiService';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';


import { useParams } from 'react-router-dom'


const Log = (props) => {
    const { id } = useParams();

    const [logActivities, setLogActivities] = useState([]);
    const [location_id] = useState(id);
    const [location,setLocation] = useState();
    const [city,setCity] = useState();
    const [current,setCurrent] = useState(0);

    React.useEffect(() =>{
        getLocation();
        getCurrent();
        getLog();

    }, []);

    const getLog = async () => {
        try{
            console.log(location_id);
            const data = await ApiService.getLocationsLog(location_id);
            setLogActivities(data);

           
        }catch(error){
           console.log(error);
        }
    }
    const getLocation = async () => {
        try{
            const data = await ApiService.getLocation(location_id);
            
            setLocation(data[0].name);
            setCity(data[0].cname);
            console.log(data[0]);
        }catch(error){
           console.log(error);
        }
    }

    const getCurrent = async () => {
        try{
            const data = await ApiService.getLocationsCurrent(location_id);
            setCurrent(data.current_users);
            console.log(data);
        }catch(error){
           console.log(error);
        }
    }


    return (
        <React.Fragment>
            <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/" >
                Home
            </Link>
            <Link color="inherit" href="/dashboard" >
                Dashboard
            </Link>
            <Typography color="textPrimary">Log</Typography>
            </Breadcrumbs>
            <Typography variant="h1" className="mb-2">{location +', '+city}</Typography>
      

            

            <Card >
                <CardContent>
                    {current}
                </CardContent>
                </Card>


            <Typography variant="h5" className="mb-2">Actividad</Typography>
            <TableContainer component={Paper}>
            <Table size="small" className="mb-2">
            <TableHead>
              <TableRow>
                <TableCell align="left">Hora</TableCell>
                <TableCell>Quien</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            { logActivities.map((actividad, index) => (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>{actividad.time}</TableCell>
                    <TableCell>{actividad.name + ' '+ actividad.unidad_operativa}</TableCell>
                    </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        
    </TableContainer>
        </React.Fragment>
    )
}

export default Log;