import { v4 as uuid } from 'uuid';

// @material-ui/icons
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';

// pages
import Home from '../pages/Home/Home';
import Log from '../pages/Log/Log';
import Dashboard from '../pages/Dashboard/Dashboard';
import Info from '../pages/Info/Info';

export default [
    {
        id: uuid(),
        path: '/',
        component: Home,
        label: 'Home',
        icon: HomeOutlinedIcon,
        isNavLink: true
    },
    {
        id: uuid(),
        path: '/location/:id/log',
        component: Log,
        label: 'Log'
    },
    {
        id: uuid(),
        path: '/dashboard',
        component: Dashboard,
        label: 'Dashboard'
    },
    {
        id: uuid(),
        path: '/info',
        component: Info,
        label: 'Info'
    }
]