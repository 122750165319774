import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';

import Box from "@material-ui/core/Box";
import LogoConnection from "../../assets/images/logos/logo.png";
import ScanImage from '../../assets/images/scan/scan.gif'

import * as ApiService from '../../services/ApiService';
import { ThreeDots } from 'svg-loaders-react';
import QRCode from '../../shared/components/QRCode/QRCode';
import { Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Paper from '@material-ui/core/Paper';

const Home = (props) => {
    const [token, setToken] = useState("");
    const { location, scanMode } = props;
    //const [location, setLocation] = useState(1);
    //const [scanMode, setScanMode] = useState("");
    const [loading, setLoading] = useState(false);
    const [person, setPerson] = useState();
    const [personTimer, setPersonTimer] = useState();
    const [personError, setPersonError] = useState(false);
    const [personErrorTimer, setPersonErrorTimer] = useState();
    const [registro, setRegistro] = useState(false);

    const textInput = React.useRef(null);
    const useStyles = makeStyles((theme) => ({
        root: {
          display: 'flex',
          flexWrap: 'wrap',
          '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
          }},
          paper: {
            marginTop: theme.spacing(10) ,
            marginBottom: theme.spacing(10) ,
            margin:'auto',
            maxWidth: "500px",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding:theme.spacing(5)
            },
        inputQr:{
            border: "none",
            "&:focus": {
                border: "none",
                outline: "none"
              },
              zIndex: -1,
            position: "relative"
        },
        alertRegistro:{
            marginTop:theme.spacing(3)
        }
      }));
      const classes = useStyles();
    React.useEffect(() => {
        
        if (textInput.current) {
            textInput.current.focus();
        }

        window.addEventListener('keydown', upHandler);
        // cleanup this component
        return () => {
            window.removeEventListener('keydown', upHandler);
        };
    }, []);
    React.useEffect(() => {
        if (scanMode === "camera") {
            if (token !== "") {
                handleQr();
                setToken("");
            }
        }
    }, [token]);
    const upHandler = ({ key }) => {
        if (textInput.current) {
            textInput.current.focus();
        }
    };

    const handleQr = async () => {
        setLoading(true);
        console.log(token);
        const body = {
            token_person: token,
            id_location: location.id
        }
        console.log(body);
        try {
            if(personErrorTimer){
                setPersonError(false);
                clearTimeout(personErrorTimer);
            }
            if(personTimer){
                setPerson();
                clearTimeout(personTimer);
            }
            const data = await ApiService.scan(body);
            console.log(data);
            setRegistro(data.registro);
            setPerson(data.person);
            setPersonTimer( ()=>{
                setTimeout(()=>{
                    setPerson();
                }, 10000);
            });
        } catch (error) {
            console.log(error);
            setPersonError(true);
            setPersonErrorTimer( ()=>{
                setTimeout(()=>{
                    setPersonError(false);
                }, 6000);
            });
              
        }
        setLoading(false);
    };


    return (
        <React.Fragment>
            <Box display="flex" alignItems="center" justifyContent="center">
                <img alt="logo" src={LogoConnection} style={{ maxWidth: 350 }} />
            </Box>
            <div>
                <Paper className={classes.paper}>
                    {loading === true ?
                        <ThreeDots stroke="#e63832" /> :
                        <Box display="block" alignItems="center" justifyContent="center">
                            {personError === true ? <Alert severity="error">Error al Leer. Favor de Intentar nuevamente</Alert> : ""}
                            {person ?
                                <Box display="block" alignItems="center" justifyContent="center">
                                    <Typography variant="h2" align="center">Bienvenido</Typography>
                                    <Typography variant="h1" align="center">{person.name}</Typography>
                                    {registro === true ?
                                        <Alert severity="info" className={classes.alertRegistro}>
                                            <AlertTitle>Registro</AlertTitle>
                                            <Typography variant="h2" align="center">{person.kit_location}</Typography>
                                        </Alert> : ""
                                    }
                                </Box> :
                                <div>
                                    {scanMode === "camera" ?
                                        <QRCode onSuccessCallback={(code) => {
                                            setToken(code) // continue in useEffect...
                                        }} /> :
                                        <div>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center">
                                                <img alt="logo" src={ScanImage} style={{ maxWidth: 350 }} />
                                            </Box>
                                            <input ref={textInput}
                                                className={classes.inputQr}
                                                value={token}
                                                onChange={e => {
                                                    //if (token !== "") {
                                                    //    setLoading(true);
                                                    //}
                                                    console.log(e.nativeEvent.data)
                                                    setToken(token + e.nativeEvent.data);
                                                }}
                                                onKeyUp={e => {
                                                    if (e.key === 'Enter') {
                                                        console.log("token:" + token);
                                                        handleQr();
                                                        setToken("");
                                                    }
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                        </Box>
                    }
                    {/*<input ref={textInput}
                        className={classes.inputQr}
                        value={token}
                        onChange={e => {
                            if (token !== "") {
                                setLoading(true);
                            }
                            console.log(e.nativeEvent.data)
                            setToken(token + e.nativeEvent.data);
                        }}
                        onKeyUp={e => {
                            if (e.key === 'Enter') {
                                console.log("token:" + token);
                                handleQr();
                                setToken("");
                            }
                        }}
                    />*/}
                </Paper>
            </div>
            {/*<Box display="flex" alignItems="center" justifyContent="center">
                <img alt="logo" src={ScanImage} style={{ maxWidth: 350 }} />
            </Box>*/}
        </React.Fragment>
    )
}

const mapStateToProps = ({ drawerReducer, locationReducer }) => ({
    scanMode: drawerReducer.scanMode,
    location: locationReducer.location
});

export default connect(mapStateToProps, {})(Home);