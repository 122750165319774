import React from 'react';

var w = window;

class QrcodeScannerV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cameraId: null,
            cameras: [],
            lastResult: null
        };
        
        this.onScanSuccess = this.onScanSuccess.bind(this);
        this.onScanError = this.onScanError.bind(this);
    }
    onScanSuccess(qrCodeMessage) {
        if (this.state.lastResult !== qrCodeMessage) {
            this.setState({ ...this.state, lastResult: qrCodeMessage });

            if (this.props.qrCodeSuccessCallback) {
                this.props.qrCodeSuccessCallback(qrCodeMessage);
            }
        }
    }
    onScanError(qrCodeError) {
        if (this.props.qrCodeErrorCallback) {
            this.props.qrCodeErrorCallback(qrCodeError);
        }
    }
    componentDidMount() {
        const config = createConfig(this.props);
        const verbose = this.props.verbose === true;

        w.Html5Qrcode.getCameras().then(devices => {
            if (devices && devices.length > 0) {
                const cameraId = devices[0].id;

                this.setState({
                    ...this.state,
                    cameraId: cameraId,
                    cameras: devices
                }, () => {
                    this.html5QrCode = new w.Html5Qrcode("qr-reader", verbose);
                    //this.html5QrCode.start({ deviceId: { exact: cameraId } }, config, this.onScanSuccess);
                    this.html5QrCode.start({ facingMode: "environment" }, config, this.onScanSuccess);
                });
            }
        }).catch(err => {
            console.error('Failed to get camera devices. ', err);
        });
    }
    componentWillUnmount() {
        if (this.html5QrCode) {
            this.html5QrCode.stop().then(ignore => {
                console.log('Successful stop QrcodeScanner.', ignore);
            }).catch(error => {
                console.error('Failed to stop QrcodeScanner. ', error);
            });
        }
    }
    render() {
        return <div id="qr-reader"></div>
    }
}

const createConfig = (props) => {
    const config = {};

    if (props.fps) {
        config.fps = props.fps;
    }

    if (props.qrBox) {
        config.qrbox = props.qrBox;
    }

    if (props.aspectRatio) {
        config.aspectRatio = props.aspectRatio;
    }

    if (props.disableFlip) {
        config.disableFlip = false; //props.disableFlip;
    }

    return config;
}

export default QrcodeScannerV2;